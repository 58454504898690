import './Auth.css';
import { useState } from 'react';
import { auth } from '../../config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export default function Auth() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = (e: any) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate('/resultados');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="authContainer">
      <h2>Ingresa tus credenciales para acceder al contenido</h2>
      <input
        placeholder="Correo"
        onChange={(e) => setEmail(e.target.value)}
      ></input>
      <input
        placeholder="Contraseña"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      ></input>
      <button onClick={onLogin}>Entrar</button>
    </div>
  );
}
