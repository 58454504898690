import Diversity1Icon from '@mui/icons-material/Diversity1';
import SchoolIcon from '@mui/icons-material/School';
import RecyclingIcon from '@mui/icons-material/Recycling';
import BalanceIcon from '@mui/icons-material/Balance';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';

export const EJES = [
  {
    ejeTitutlo: 'Salud y Vida Digna',
    valor: 'salud',
    ejeContenido: 'Lorem Impsum',
    icon: <Diversity1Icon />
  },
  {
    ejeTitutlo: 'Educación, cultura y deporte',
    valor: 'educacion',
    ejeContenido: 'Lorem Impsum',
    icon: <SchoolIcon />
  },
  {
    ejeTitutlo: 'Economía dinámica y prosperidad familiar',
    valor: 'economia',
    ejeContenido: 'Lorem Impsum',
    icon: <HomeIcon />
  },
  {
    ejeTitutlo: 'Medio ambiente e infraestructura sostenible',
    valor: 'medio_ambiente',
    ejeContenido: 'Lorem Impsum',
    icon: <RecyclingIcon />
  },
  {
    ejeTitutlo: 'Paz y respeto a la ley',
    valor: 'paz',
    ejeContenido: 'Lorem Impsum',
    icon: <BalanceIcon />
  },
  {
    ejeTitutlo: 'Gobierno ciudadano',
    valor: 'gobierno',
    ejeContenido: 'Lorem Impsum',
    icon: <GroupsIcon />
  }
];

export interface ObjetivosType {
  eje: string;
  valor: number;
  select: string;
  descripcionObjetivo: string;
}

export const OBJETIVOS = [
  {
    eje: 'salud',
    valor: 1.1,
    select: '1.1',
    descripcionObjetivo:
      'Lograr que todas las personas ejerzan de manera efectiva, equitativa e igualitaria el derecho a una vida digna'
  },
  {
    eje: 'salud',
    valor: 1.2,
    select: '1.2',
    descripcionObjetivo:
      'Contar con un sistema de salud digno, eficiente, y eficaz, con acceso para todas las personas, en especial para las que más lo necesiten'
  },
  {
    eje: 'educacion',
    valor: 2.1,
    select: '2.1',
    descripcionObjetivo:
      'Lograr que todas las personas tengan las mismas oportunidades y condiciones para tener educación de calidad'
  },
  {
    eje: 'educacion',
    valor: 2.2,
    select: '2.2',
    descripcionObjetivo:
      'Incentivar que las personas tengan una vida físicamente activa'
  },
  {
    eje: 'educacion',
    valor: 2.3,
    select: '2.3',
    descripcionObjetivo:
      'Asegurar que todas las personas tengan acceso a la cultura'
  },
  {
    eje: 'economia',
    valor: 3.1,
    select: '3.1',
    descripcionObjetivo:
      'Consolidar una economía próspera e innovadora, con buenas condiciones laborales y capacidades de proveeduría y emprendimiento, con un desarrollo equilibrado entre sus regiones'
  },
  {
    eje: 'economia',
    valor: 3.2,
    select: '3.2',
    descripcionObjetivo: 'Impulsar el desarrollo de sectores estratégicos'
  },
  {
    eje: 'medio_ambiente',
    valor: 4.1,
    select: '4.1',
    descripcionObjetivo:
      'Lograr una relación sustentable y sostenible con el medio ambiente a través de un nuevo ordenamiento territorial, un desarrollo integral con nuevas fuentes de movilidad, la preservación de la biodiversidad en áreas naturales y zonas metropolitanas'
  },
  {
    eje: 'medio_ambiente',
    valor: 4.2,
    select: '4.2',
    descripcionObjetivo:
      'Lograr una economía circular y una gobernanza hídrica que permita su aprovechamiento y sostenibilidad'
  },
  {
    eje: 'medio_ambiente',
    valor: 4.3,
    select: '4.3',
    descripcionObjetivo:
      'Contar con fuentes de energía competitivas y ambientalmente sostenibles'
  },
  {
    eje: 'paz',
    valor: 5.1,
    select: '5.1',
    descripcionObjetivo:
      'Lograr un Querétaro de respeto a las leyes, en paz y tranquilidad'
  },
  {
    eje: 'gobierno',
    valor: 6.1,
    select: '6.1',
    descripcionObjetivo:
      'Contar con un gobierno eficaz, eficiente y con finanzas públicas sanas'
  },
  {
    eje: 'gobierno',
    valor: 6.2,
    select: '6.2',
    descripcionObjetivo:
      'Consolidar un gobierno abierto, que escucha y empodera a ciudadanía y que garantiza su derecho de acceso a la información y que rinde cuentas'
  },
  {
    eje: 'gobierno',
    valor: 6.3,
    select: '6.3',
    descripcionObjetivo:
      'Asegurar un gobierno profesional e íntegro que cuenta con la confianza de la ciudadanía'
  }
];
