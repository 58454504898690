import * as React from 'react';
import './Ejes.css';
import Eje from './Eje/Eje';
import { EJES } from '../../constants';

export default function Ejes() {
  return (
    <div className="rootEjes">
      {EJES.map((eje, index) => (
        <Eje
          ejeTitulo={eje.ejeTitutlo}
          ejeValor={eje.valor}
          key={index}
          icon={eje.icon}
        ></Eje>
      ))}
    </div>
  );
}
