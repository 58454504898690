import * as React from 'react';
import './Column.css';

type ColumnProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  justifyContent?: string;
  alignItems?: string;
  padding?: string;
};

export default function Column({
  children,
  backgroundColor,
  justifyContent,
  alignItems
}: ColumnProps) {
  return (
    <div
      className="rootColumn"
      style={{
        backgroundColor: backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: justifyContent,
        alignItems: alignItems,
        height: 'auto'
      }}
    >
      {children}
    </div>
  );
}
