import * as React from 'react';
import FlexRowSection from '../FlexRowSection/FlexRowSection';
import Column from '../Column/Column';
import Ejes from '../Ejes/Ejes';
import { ParticipaForm } from '../ParticipaForm/ParticipaForm';
import { db } from '../../config/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../config/firebase';

export default function Seccion4() {
  /* eslint-disable */

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
    } else {
    }
  });

  const participaCollectionRef = collection(db, 'respuestas');

  const onSubmitParticipaForm = async (
    tipo: string,
    nombre: string,
    cp: string,
    objetivo: string,
    eje: string,
    opinion: string
  ) => {
    try {
      await addDoc(participaCollectionRef, {
        tipo: tipo,
        nombre: nombre,
        cp: cp,
        objetivo: objetivo,
        eje: eje,
        opinion: opinion
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <FlexRowSection>
      <Column justifyContent="start">
        <h3
          style={{ fontSize: '38px', color: '#002454', fontWeight: '500' }}
          className="textoBienvenida"
        >
          Ejes y objetivos
        </h3>
        <Ejes></Ejes>
      </Column>
      <Column justifyContent="start">
        <h3
          style={{ fontSize: '38px', color: '#002454', fontWeight: '500' }}
          id="participa"
        >
          Tus datos
        </h3>
        <ParticipaForm
          onSubmit={({ tipo, nombre, cp, objetivo, eje, opinion }) => {
            onSubmitParticipaForm(tipo, nombre, cp, objetivo, eje, opinion);
          }}
        />
      </Column>
    </FlexRowSection>
  );
}
