import './Resultados.css';
import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
// import { auth } from '../../config/firebase';
import { sampleResponse } from '../../helpers';
import TableContainer from '@mui/material/TableContainer';
import { responseType } from '../../helpers';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

export default function Resultados() {
  const [userID, setCurrentUserID] = useState('');
  const [respuestas, setRespuetas] = useState<responseType[]>([]);

  const respuestasCollectionRef = collection(db, 'respuestas');

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserID(user.uid);
      }
    });

    const getRespuestas = async () => {
      try {
        // setRespuetas(sampleResponse);

        const data = await getDocs(respuestasCollectionRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id
        }));
        setRespuetas(filteredData);
      } catch (err) {
        console.error(err);
      }
    };

    getRespuestas();
  }, []);
  return (
    <>
      {userID.length >= 1 && (
        <>
          <div style={{ margin: '10px' }}>
            <div>
              <h2>Resultados consulta: Querétaro 2050</h2>
              <h4>Respuestas hasta ahora: {respuestas.length}</h4>
            </div>
            {/* <pre>{JSON.stringify(respuestas, null, 2)}</pre> */}
            {respuestas.length >= 1 && (
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="resultados table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Eje</TableCell>
                      <TableCell>Objetivo</TableCell>
                      <TableCell>Tipo Respuesta</TableCell>
                      <TableCell>Respuesta</TableCell>
                      <TableCell>CP</TableCell>
                      <TableCell>Nombre</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {respuestas.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.eje}</TableCell>
                        <TableCell>{row.objetivo}</TableCell>
                        <TableCell>{row.tipo}</TableCell>
                        <TableCell>{row.opinion}</TableCell>
                        <TableCell>{row.cp}</TableCell>
                        <TableCell>{row.nombre}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {respuestas.length == 0 && (
              <h2 style={{ color: 'red' }}>Aún no hay respuestas</h2>
            )}
          </div>
        </>
      )}
      {userID.length < 1 && (
        <div className="center">
          <h2 style={{ color: 'red' }}>
            Necesitas una cuenta para consultar esta información
          </h2>
        </div>
      )}
    </>
  );
}
