import './Home.css';
import NavBar from '../NavBar/NavBar';
import Seccion1 from '../Seccion1/Seccion1';
import Seccion2 from '../Seccion2/Seccion2';
import Seccion3 from '../Seccion3/Seccion3';
import Seccion4 from '../Seccion4/Seccion4';
import Footer from '../Footer/Footer';
import { ThemeProvider, createTheme } from '@mui/material';

export default function Home() {
  /* eslint-disable */

  const theme = createTheme({
    palette: {
      primary: {
        main: '#002454'
      },
      secondary: {
        main: '#d81d72'
      }
    },
    typography: {
      h1: {
        fontFamily: 'Archivo',
        fontSize: 70,
        fontWeight: 500
      },
      h2: {
        fontFamily: 'Archivo',
        fontSize: 50,
        fontWeight: 500
      },
      h3: {
        fontFamily: 'Archivo',
        fontSize: 70,
        fontWeight: 700
      },
      h4: {
        fontFamily: 'Archivo',
        fontSize: 50,
        fontWeight: 700
      },
      h5: {
        fontFamily: 'Archivo',
        fontSize: 21,
        fontWeight: 500
      },
      body1: {
        fontFamily: 'Archivo',
        fontSize: 19,
        fontWeight: 600
      }
    }
  });

  return (
    <div className="homeContainer">
      <ThemeProvider theme={theme}>
        <NavBar></NavBar>
        <Seccion1 />
        <Seccion2 />
        <Seccion3 />
        <Seccion4 />
        <Footer />
      </ThemeProvider>
    </div>
  );
}
