import * as React from 'react';
import FlexRowSection from '../FlexRowSection/FlexRowSection';
import Column from '../Column/Column';
import { Typography, Link } from '@mui/material';
import planEstrategico from '../../files/Plan_Estrategio_Queretaro2050.pdf';

export default function Footer() {
  /* eslint-disable */
  return (
    <div
      style={{
        marginTop: '20px'
      }}
    >
      <FlexRowSection>
        <Column backgroundColor="#d81d72">
          <div>
            <Typography
              variant="body1"
              sx={{ color: '#EEEEF1', padding: '30px' }}
            >
              El Poder Ejecutivo del Estado de Querétaro agradece tu invaluable
              tiempo dedicado a estas aportaciones que enriquecerán este
              proyecto esencial para el futuro de nuestro estado.
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: '#EEEEF1', padding: '30px' }}
            >
              Para conocer la versión preliminar del Plan Querétaro 2050,{' '}
              <Link download href={planEstrategico} style={{ color: 'black' }}>
                descarga aquí
              </Link>
            </Typography>
          </div>
        </Column>
      </FlexRowSection>
      <FlexRowSection>
        <Column alignItems="center" justifyContent="center">
          <div>
            <Link
              href={'https://queretaro.gob.mx/web/aviso-de-privacidad'}
              target="_blank"
            >
              Aviso de privacidad
            </Link>
          </div>
          <div>
            <Link href={'mailto:consulta@queretaro2050.mx'} target="_blank">
              consulta@queretaro2050.mx
            </Link>
          </div>
        </Column>
      </FlexRowSection>
    </div>
  );
}
