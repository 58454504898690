import * as React from 'react';
import FlexRowSection from '../FlexRowSection/FlexRowSection';
import Column from '../Column/Column';
import { Typography } from '@mui/material';

export default function Seccion3() {
  /* eslint-disable */
  return (
    <FlexRowSection>
      <Column justifyContent="center">
        <div>
          <Typography
            variant="h1"
            sx={{ color: '#002454' }}
            className="textoBienvenida"
          >
            Participa
          </Typography>
          <Typography
            variant="h4"
            sx={{ color: '#4B93C5' }}
            className="textoBienvenida"
          >
            Plan Querétaro 2050
          </Typography>
        </div>
      </Column>
      <Column justifyContent="center">
        <div>
          <Typography variant="body1" sx={{ color: '#002454' }}>
            A continuación puedes comentar y compartir tus propuestas sobre los
            <b> objetivos estratégicos</b> preliminares para el Plan QRO 2050.
            Es importante destacar que estos objetivos son propuestas iniciales
            que serán enriquecidas con tus aportaciones. Se dividen en los 6
            ejes definidos en el Plan Estatal de Desarrollo 2021&#45;2027. En
            caso que tu comentario o propuesta no se relacione con alguno de los
            ejes u objetivos planteados, en la caja de Eje da click en la opción
            de Otro.
          </Typography>
        </div>
      </Column>
    </FlexRowSection>
  );
}
