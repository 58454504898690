import * as React from 'react';
import { useState } from 'react';
import { Grid, Typography, Button, Box, Link } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Modal from '@mui/material/Modal';
import guiaPdf from '../../../files/guia_participacion.pdf';
import reglasPdf from '../../../files/reglas_de_convivencia.pdf';
import videoTutorial from '../../../files/video_tutorial.mp4';

type DownloadRowProps = {
  titulo: string;
  pdf?: string;
  download: boolean;
};

export default function DownloadRow({
  titulo,
  pdf,
  download
}: DownloadRowProps) {
  /* eslint-disable */

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const justifyContent = download ? 'space-between' : 'end';

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">{titulo}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: justifyContent,
              alignItems: 'center'
            }}
          >
            {download && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <RemoveRedEyeIcon
                  sx={{ color: '#EEEFF1' }}
                  fontSize="small"
                ></RemoveRedEyeIcon>
                <Link
                  variant="body1"
                  underline="hover"
                  color="#EEEFF1"
                  sx={{ color: '#EEEFF1' }}
                  onClick={handleOpen}
                >
                  {' '}
                  Vista Previa
                </Link>
              </Box>
            )}
            <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%',
                  height: '80%',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4
                }}
              >
                {download && (
                  <object
                    data={pdf === 'guia' ? guiaPdf : reglasPdf}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  ></object>
                )}
                {!download && (
                  <video width="100%" height="100%" controls>
                    <source src={videoTutorial} type="video/mp4"></source>
                  </video>
                )}
              </Box>
            </Modal>

            {download && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  href={pdf === 'guia' ? guiaPdf : reglasPdf}
                  download
                  style={{ minWidth: '120px' }}
                >
                  Descargar
                </Button>
              </Box>
            )}
            {!download && (
              <Box sx={{ alignSelf: 'end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  style={{ alignSelf: 'end', minWidth: '120px' }}
                >
                  Ver video
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
