import * as Yup from 'yup';

export const ParticipaFormSchema = Yup.object().shape({
  tipo: Yup.string().required('Campo Obligatorio'),
  nombre: Yup.string()
    .min(2, 'Tú nombre tiene qué tener al menos dos caracteres')
    .max(300, 'Tú nombre no puede tener tantos caracteres')
    .required('Campo Obligatorio'),
  cp: Yup.string()
    .min(5, 'El Código Postal tiene que tener al menos 5 caracteres')
    .max(5, 'El Código Postal tiene que tener máximo 5 carácteres')
    .required('Campo Obligatorio'),
  opinion: Yup.string()
    .min(1, 'Tu comentario tiene que tener al menos 1 caracter')
    .max(1800, 'Llegaste al máximo de caracteres por comentario')
    .required('Campo Obligatorio'),
  eje: Yup.string().required('Campo Obligatorio'),
  objetivo: Yup.string().required('Campo Obligatorio')
});
