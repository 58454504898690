import * as React from 'react';
import { useState } from 'react';
import './ParticipaForm.css';
import {
  Button,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Grid
} from '@mui/material';
import { useFormik } from 'formik';
import { ParticipaFormSchema } from '../../schemas';
import { EJES, OBJETIVOS, ObjetivosType } from '../../constants';

interface Values {
  tipo: string;
  nombre: string;
  cp: string;
  eje: string;
  objetivo: string;
  opinion: string;
}
interface Props {
  onSubmit: (values: Values) => void;
}

export const ParticipaForm: React.FC<Props> = ({ onSubmit }) => {
  const {
    values,
    setFieldValue,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isSubmitting
  } = useFormik({
    initialValues: {
      tipo: '',
      nombre: '',
      cp: '',
      opinion: '',
      eje: '',
      objetivo: ''
    },
    validationSchema: ParticipaFormSchema,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
      alert('Recibimos tu comentarios, ¡Gracias!"');
    }
  });

  const [filteredObjetivos, setFilteredObjetivos] = useState<
    ObjetivosType[] | null[]
  >([]);

  const objetivosFilterHandler = (eje: string) => {
    const filteredObject = OBJETIVOS.filter((obj) => obj.eje === eje);
    setFilteredObjetivos(filteredObject);
  };

  return (
    <Box sx={{ minWidth: 120 }} component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <TextField
              select
              label="Tipo de comentario"
              id="tipo-opinion-select"
              variant="filled"
              value={values.tipo}
              InputProps={{ disableUnderline: true }}
              sx={{ borderRadius: '10px' }}
              onChange={(e) => setFieldValue('tipo', e.target.value as string)}
              error={touched.tipo && Boolean(errors.tipo)}
              helperText={touched.tipo ? errors.tipo : ''}
              disabled
            >
              <MenuItem value="comentario">Comentario</MenuItem>
              <MenuItem value="propuesta">Propuesta</MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              placeholder="Nombre"
              label="Nombre"
              name="nombre"
              id="nombre-text"
              variant="filled"
              InputProps={{ disableUnderline: true }}
              sx={{ borderRadius: '10px' }}
              value={values.nombre}
              onChange={(e) =>
                setFieldValue('nombre', e.target.value as string)
              }
              onBlur={handleBlur}
              error={touched.nombre && Boolean(errors.nombre)}
              helperText={touched.nombre ? errors.nombre : ''}
              fullWidth
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <TextField
              placeholder="Código Postal"
              label="Código Postal"
              name="cp"
              variant="filled"
              sx={{ borderRadius: '10px' }}
              InputProps={{ disableUnderline: true }}
              value={values.cp}
              onChange={(e) => setFieldValue('cp', e.target.value as string)}
              onBlur={handleBlur}
              error={touched.cp && Boolean(errors.cp)}
              helperText={touched.cp ? errors.cp : ''}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <TextField
              select
              id="demo-simple-select"
              variant="filled"
              value={values.eje}
              label="Eje"
              InputProps={{ disableUnderline: true }}
              sx={{ borderRadius: '10px' }}
              onChange={(e) => {
                setFieldValue('eje', e.target.value as string);
                if (e.target.value === 'otro') {
                  setFieldValue('objetivo', 'otro');
                }
                objetivosFilterHandler(e.target.value as string);
              }}
              error={touched.eje && Boolean(errors.eje)}
              helperText={touched.eje ? errors.eje : ''}
              disabled
            >
              {EJES.map((eje, index) => (
                <MenuItem value={eje.valor} key={index}>
                  {eje.ejeTitutlo}
                </MenuItem>
              ))}
              <MenuItem value="otro">Otro</MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <TextField
              select
              id="objetivo-select"
              value={values.objetivo}
              variant="filled"
              label="Objetivo"
              InputProps={{ disableUnderline: true }}
              disabled
              sx={{ borderRadius: '10px' }}
              onChange={(e) =>
                setFieldValue('objetivo', e.target.value as string)
              }
              error={touched.objetivo && Boolean(errors.objetivo)}
              helperText={touched.objetivo ? errors.objetivo : ''}
            >
              {filteredObjetivos?.map((obj, index) => (
                <MenuItem value={obj?.valor} key={index}>
                  {obj?.select}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <TextField
              name="opinion"
              label="Tu comentario o propuesta"
              multiline
              rows={6}
              placeholder="Empieza a escribir... "
              variant="filled"
              InputProps={{ disableUnderline: true }}
              value={values.opinion}
              onChange={(e) =>
                setFieldValue('opinion', e.target.value as string)
              }
              onBlur={handleBlur}
              fullWidth
              sx={{ borderRadius: '10px' }}
              error={touched.opinion && Boolean(errors.opinion)}
              helperText={touched.opinion ? errors.opinion : ''}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Button
            type="submit"
            variant="contained"
            disableRipple
            disableElevation
            disabled
          >
            Envía tu comentario
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
