import {
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import Resultados from './components/Resultados/Resultados';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/login',
    element: <Auth />
  },
  {
    path: '/resultados',
    element: <Resultados />
  }
]);

export default function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  return (
    <div>
      <Routes></Routes>
    </div>
  );
}
