import * as React from 'react';
import FlexRowSection from '../FlexRowSection/FlexRowSection';
import Column from '../Column/Column';
import DownloadRow from './DownloadRow/DownloadRow';
import { Grid, Typography } from '@mui/material';
import { useState } from 'react';

export default function Seccion2() {
  /* eslint-disable */

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <FlexRowSection>
      <Column backgroundColor="#7DC6D9" justifyContent="center">
        <div>
          <div>
            <Typography
              variant="h3"
              sx={{ color: '#002454' }}
              className="textoBienvenida"
            >
              <b>Plan Querétaro 2050:</b>
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: '#002454' }}
              className="textoBienvenida"
            >
              un documento inédito en nuestro estado
            </Typography>
          </div>

          <Typography variant="body1" sx={{ color: '#002454' }} mt={4} mb={4}>
            Este documento{' '}
            <b>
              servirá de guía para la formulación de los grandes proyectos que
              cuiden el porvenir de nuestras siguientes generaciones
            </b>
            y anticipen riesgos que pongan en peligro la calidad de vida en
            nuestro estado. <br></br>
            <br></br>Tu participación es fundamental para que este proyecto, que
            estará incorporado en el marco legal de nuestro estado, contenga las
            aportaciones de la mayor cantidad posible de personas que habitan en
            el estado.{' '}
            <span
              onClick={toggleReadMore}
              className="read-or-hide"
              style={{ color: '#002454', fontWeight: '700' }}
            >
              {isReadMore ? '...leer más' : ' mostrar menos'}
            </span>
          </Typography>
          {!isReadMore && (
            <Typography variant="body1" sx={{ color: '#002454' }}>
              El Plan QRO 2050 se ha venido construyendo a través de un proceso
              participativo con los diversos actores de la sociedad, quienes han
              aportado ideas para la formulación de los objetivos estratégicos
              de largo plazo. <br></br>
              <br></br>A la fecha, el proceso participativo ha contado con la
              participación de 156 miembros de los 20 consejos ciudadanos y
              especialistas que aportaron 218 propuestas con las cuales se
              concluyeron los objetivos que a continuación se listan y en la que
              se te solicitarán tus aportaciones para enriquecer el proceso de
              la elaboración del Plan QRO 2050.
            </Typography>
          )}
        </div>
      </Column>
      <Column backgroundColor="#d81d72" justifyContent="center">
        <div id="comoParticipar">
          <Typography
            variant="h2"
            sx={{ color: '#002454' }}
            className="textoBienvenida"
          >
            ¿Cómo participar?
          </Typography>
          <Typography variant="body1" sx={{ color: '#EEEFF1' }} mt={4} mb={4}>
            Consulta las Reglas de Convivencia y Participación para orientarte
            en la redacción de tus comentarios. Si buscas ayuda sobre el uso de
            esta plataforma, te recomendamos consultar la Guía de Participación.
          </Typography>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12}>
              <DownloadRow
                titulo={'Reglas de convivencia y participación'}
                pdf="reglas"
                download
              ></DownloadRow>
            </Grid>
            <Grid item xs={12}>
              <DownloadRow
                titulo={'Guía de Participación'}
                pdf="guia"
                download
              ></DownloadRow>
            </Grid>
            <Grid item xs={12}>
              <DownloadRow
                titulo={'Video tutorial'}
                download={false}
              ></DownloadRow>
            </Grid>
          </Grid>
        </div>
      </Column>
    </FlexRowSection>
  );
}
