import * as React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OBJETIVOS } from '../../../constants';

type EjeProps = {
  key: React.Key;
  ejeTitulo: string;
  ejeValor: string;
  icon?: JSX.Element;
};
export default function Eje({ ejeTitulo, ejeValor, icon }: EjeProps) {
  const filteredObjetivos = OBJETIVOS.filter((obj) => obj.eje === ejeValor);
  return (
    <Accordion
      square={false}
      disableGutters
      elevation={0}
      sx={{
        borderRadius: '10px',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: '#EEEFF1' }} />}
        sx={{
          backgroundColor: '#002454',
          color: '#EEEFF1',
          fontSize: '21px',
          fontWeight: '500',
          borderRadius: '10px',
          borderTop: 'none'
        }}
      >
        <Stack alignItems="center" direction="row" gap={2}>
          {icon}
          <Typography variant="body1">{ejeTitulo}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderRadius: '10px'
        }}
      >
        {filteredObjetivos.map((obj, index) => (
          <p key={index}>
            <b>Objetivo estratégico {obj.select}: </b>
            {obj.descripcionObjetivo}
          </p>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
