import * as React from 'react';
import './Seccion1.css';
import FlexRowSection from '../FlexRowSection/FlexRowSection';
import Column from '../Column/Column';
import { Button } from '@mui/material';
import qtoCiudad from '../../images/vector_qro.png';
import { Typography, Box } from '@mui/material';

export default function Seccion1() {
  /* eslint-disable */
  return (
    <FlexRowSection>
      <Column
        backgroundColor="#002454"
        justifyContent="center
      "
      >
        <div>
          <Typography
            variant="h1"
            sx={{ color: '#EEEFF1' }}
            className="textoBienvenida"
          >
            Bienvenida y bienvenido a la  elaboración del
          </Typography>
          <Typography
            variant="h3"
            sx={{ color: '#4B93C5' }}
            className="textoBienvenida"
          >
            Plan Querétaro 2050
          </Typography>

          <Box mt={4}>
            <Typography variant="body1" sx={{ color: 'white' }} mt={4} mb={4}>
              Una plataforma de participación ciudadana para construir con tus
              comentarios el Querétaro que queremos y podemos tener en el
              futuro.
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              className="participaButton"
              onClick={() => {
                const element = document.getElementById('participa');
                element?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
            >
              Participa
            </Button>
          </Box>
        </div>
      </Column>
      <Column
        backgroundColor="#002454"
        justifyContent="center"
        alignItems="center"
      >
        <img src={qtoCiudad} alt="logo_queretaro" className="logo_img" />
      </Column>
    </FlexRowSection>
  );
}
