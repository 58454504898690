import * as React from 'react';

import './NavBar.css';
import planeacion from '../../images/planeacion.png';
import consejo from '../../images/consejo.png';
import { Link } from '@mui/material';
import FlexRowSection from '../FlexRowSection/FlexRowSection';
import Column from '../Column/Column';

export default function NavBar() {
  return (
    <FlexRowSection>
      <Column backgroundColor="#f8f7f7">
        <div className="navbar">
          <div className="logos">
            <img src={planeacion} alt="logo_planeacion" className="logo" />
            <img src={consejo} alt="logo_consejo" className="logo" />
          </div>
          <div className="ligas">
            <Link
              sx={{ color: '#002454', fontSize: '18px', fontWeight: '600' }}
              underline="hover"
              onClick={() => {
                const element = document.getElementById('comoParticipar');
                element?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
            >
              ¿Cómo participar?
            </Link>
            <Link
              sx={{ color: '#002454', fontSize: '18px', fontWeight: '600' }}
              underline="hover"
              onClick={() => {
                const element = document.getElementById('participa');
                element?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
            >
              Participa
            </Link>
          </div>
        </div>
      </Column>
    </FlexRowSection>
  );
}
